body {
  background-color: #000;
}


.tooltip {
  @apply absolute inset-x-0 top-0 text-center p-5 m-auto my-4 rounded-md bg-red-500 text-white w-96 text-xs italic;
}

.typing-text {
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .15em;
  animation: typing 1.5s steps(30, end);
}

.blink-underscore {
  animation: blink-underscore 1s step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-underscore {
  from, to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.menu-item > .blink-underscore {
  color: transparent;
}
.menu-item:hover > .blink-underscore {
  color: green;
}
